import React from 'react'

export default function Calendar() {

  const info = [
    {
      day: "Monday",
      classes: ["5 PM: Youth Gloves Up", "7 PM: Strength & Conditioning"],
    },
    {
      day: "Tuesday",
      classes: ["7 PM: Technical Boxing L1"],
    },
    {
      day: "Wednesday",
      classes: ["6:30 PM: Hit Hard, HIIT Harder", "7 PM: Technical Sparring & Footwork"],
    },
    {
      day: "Thursday",
      classes: ["8 AM: Strength & Conditioning", "5 PM: Youth Gloves Up", "7 PM: Technical Boxing L2"],
    },
    {
      day: "Friday",
      classes: ["6:30 PM: Cardio Boxing"],
    },
    {
      day: "Saturday",
      classes: ["11 AM: HIIT/MITT Fever"],
    },
  ]
  return (
    <div className='calendar-parent'>
      <div className='justify-content-evenly calendar'>
        {info.map((day, index) => (
          <div className={`col-2 day-parent${day.day === "Monday" ? "-monday" : ""}`} key={index}>
            <div className="day">
              {day.day}
            </div>
            {day.classes.map((classes, i) => (
              <div className="day-classes" key={i}>{classes}</div>
            ))}
          </div>
        ))}
      </div>
      <div className='calendar-note'>*No Classes Sunday</div>
    </div>
  )
}
